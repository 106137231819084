import { IBlog } from "../../types/blog-types";
import blog_placeholder from "../../assets/images/blog_placeholder.png";
import { convertDate } from "../../utils/helper-methods";
import { BlogBanner } from "../../configs/api-config";
import { Link } from "react-router-dom";

type TBlogsListItemProps = {
    item: IBlog;
    to: string;
};
export default function BlogsListItem(props: Readonly<TBlogsListItemProps>) {
    return (
        <Link
            className={`blog-item-cover unanchored ${props.item.type} cursor-pointer`}
            to={props.to}
        >
            <div
                className="blog-item cursor-pointer"
                style={{
                    backgroundImage: `url(${BlogBanner(props.item._id) || blog_placeholder
                        })`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            >
                <div className="blogBgCover"></div>
                <div className="blogBgHover"></div>

                <div className="blogInfo">
                    <p className="text-xl text-thick text-color-primary mb-1 blogTitle">
                        {props.item.title}
                    </p>
                    <p className="text-xs mb-1">
                        <span className="text-xs endDate2">
                            {convertDate(props.item.createdAt?.toUTCString())}
                        </span>
                        <span className="readingTime text-color-tertiary">
                            {props.item.readTime}
                        </span>
                    </p>
                    <p className="text-color-secondary text-xs mb-2 blogSubtitle">
                        {props.item.subTitle}
                    </p>
                </div>
            </div>
        </Link>
    );
}
